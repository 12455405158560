<template>
  <div class="box">
    <vue-scrolling-table
      :scroll-horizontal=true
      :scroll-vertical=true
      :sync-header-scroll=false
      :sync-footer-scroll=false
      :include-footer=false
      :dead-area-color="DDDDDD"
      :class="{ freezeFirstColumn: false }"
    >
      <template #thead>
        <tr>
          <th
            v-for="col in columns"
            :key="col.id">
            {{ col.title }}
          </th>
        </tr>
      </template>
      <template #tbody>
        <tr
          v-for="item in items"
          :key="item.name">
            <td
              v-for="col in columns"
              v-bind:style="{ 'background-color': `${cellColour(item, col)}` }"
              :key="col.id">
              {{ item[col.id] }}
            </td>
        </tr>
      </template>
    </vue-scrolling-table>
  </div>
</template>

<script>
import "/node_modules/vue-scrolling-table/dist/style.css"
import VueScrollingTable from "vue-scrolling-table"

export default {
  name: 'OptionDataTable',
  components: {
    VueScrollingTable
  },
  props: {
  },
  data: function() {
    return {
      columns: [
        { id: "0", title: "Ask", cssClasses: "" },
        { id: "1", title: "Bid", cssClasses: "" },
        { id: "2", title: "Δ", cssClasses: "" },
        { id: "3", title: "Θ", cssClasses: "" },
        { id: "4", title: "Strike", cssClasses: "" },
        { id: "5", title: "Θ", cssClasses: "" },
        { id: "6", title: "Δ", cssClasses: "" },
        { id: "7", title: "Bid", cssClasses: "" },
        { id: "8", title: "Ask", cssClasses: "" },
      ],
      items: [],
      selectedCombo: null,
    }
  },
  methods: {
    update: function(i) {
      this.items = i
    },
    setSelected(combo) {
      this.selectedCombo = combo
      console.log(this.selectedCombo)
    },
    cellColour (item, col) {
      if (this.selectedCombo === null) {
        return '#FFFFFF'
      }

      if ((this.selectedCombo[1] == item[4] && col.id == 8) ||
        (this.selectedCombo[4] == item[4] && col.id == 0)) {
        return '#AAFFAA'
      }

      if ((this.selectedCombo[3] == item[4] && col.id == 1) ||
        (this.selectedCombo[2] == item[4] && col.id == 7)) {
        return '#FFAAAA'
      }

      return '#FFFFFF'
    }
  }
}
</script>

<style>
table.scrolling td,
table.scrolling th {
	min-width: 4em;
	max-width: 4em;
	width: 4em;
  font-size: 8;
}
</style>