<template>
  <div class="box">
    <vue-scrolling-table
      :scroll-horizontal=true
      :scroll-vertical=true
      :sync-header-scroll=false
      :sync-footer-scroll=false
      :include-footer=false
      :dead-area-color="DDDDDD"
      :class="{ freezeFirstColumn: false }"
    >
      <template #thead>
        <tr>
          <th
            v-for="col in columns"
            :class="col.cssClasses"
            :key="col.id">
              {{ col.title }}
          </th>
        </tr>
      </template>
      <template #tbody>
        <tr v-for="(combo, index) in combos" :key="combo">
          <td 
            v-on:click="this.onSelect(index, combo)"
            v-for="col in columns"
            :class="col.cssClasses"
            :key="col.id">
              {{ combo[col.id] }}
          </td>
        </tr>
      </template>
    </vue-scrolling-table>
  </div>
</template>

<script>
import "/node_modules/vue-scrolling-table/dist/style.css"
import VueScrollingTable from "vue-scrolling-table"

export default {
  name: 'ComboListTable',
  components: {
    VueScrollingTable
  },
  props: {
  },
  data: function() {
    return {
      columns: [
        { id: "0", title: "K", cssClasses: "" },
        { id: "1", title: "S1", cssClasses: "" },
        { id: "2", title: "S2", cssClasses: "" },
        { id: "3", title: "S3", cssClasses: "" },
        { id: "4", title: "S4", cssClasses: "" },
        { id: "5", title: "NetΔ", cssClasses: "" },
      ],
      combos: [],
      onSelect: null,
    }
  },
  methods: {
    update: function(i, onSelect) {
      this.combos = i
      this.onSelect = onSelect
    },
  }
}
</script>
