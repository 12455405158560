<template>
  <div class="App">
    <div>
      <input 
        v-model="ticker"
        placeholder="ticker"
        @change="onTickerChanged"/>
    
      <select v-model="expiration" style="width: 16em" @change="onExpirationDateChanged">
        <option v-bind:key="exp" v-for="exp in expirationDays">{{exp}}</option>
      </select>

      <input 
        v-model="minNetDelta"
        placeholder="min net delta"/>

      <input 
        v-model="maxNetDelta"
        placeholder="max net delta"/>
    
      <button v-on:click="sendIronCondorRequest">
        Calculate
      </button>

      <input 
        readonly
        v-model="currentRequestStatus"
        placeholder="Job Status"
        style="width: 20em"/>
    </div>
 
    <div class="container" style="margin-top: 4px">
      <div>
        <div>
          <label
            class="text-center"
            style="width:50%; display:inline-block; text-align:center; background-color:#EEEEEE">
            {{ "Call" }}
          </label>
          <label
            class="text-center"
            style="width:50%; display:inline-block; text-align:center; background-color:#EEEEEE">
            {{ "Put" }}
          </label>
        </div>
        
        <OptionDataTable 
          class="item"
          ref="optionDataTable"
          style="height: 50em; width: 40em; margin: 0px 0px 0px 0px;"/>
      </div>

      <div 
        class="item"
        style="width: 30em">
        <ComboListTable
          ref="comboListTable"
          style="width: 30em; display: inline-block"/>

        <textarea 
          readonly
          placeholder="Kelly Stats"
          ref="kellyStatsBox"
          style="min-width: 35em; min-height: 49em"/>
      </div>
    </div>
  </div>
</template>

<script>
import OptionDataTable from './components/OptionDataTable.vue'
import ComboListTable from './components/ComboListTable.vue'
 
export default {
  name: 'App',
  components: {
    OptionDataTable,
    ComboListTable
  },
  data() {
    return {
      baseURL: "https://options.bskin.club", // "http://localhost",
      requestStatus: null,
      optionsData: [],
      kellyCombinations: [],
      selectedCombo: null,
      authHeader: {"secret": "eCh@4!xXGz29kYh9Za3883801s!"},
      expiration: "",
      expirationDays: [],
    }
  },
  computed: {
    currentRequestStatus() {
      if (this.requestStatus === null) {
        return ""
      }

      if (this.requestStatus.Error !== null) {
        return `Error: ${this.requestStatus.Error}`
      }

      if (this.requestStatus.PositionInQueue > 0) {
        return `Position in Queue: ${this.requestStatus.PositionInQueue}`
      }

      return `Progress: ${this.requestStatus.Progress}`
    },
  },
  methods: {
    onTickerChanged() {
      if (this.ticker == null) {
        return
      }

      this.ticker = this.ticker.toLowerCase()

      let fetchURL = new URL(this.baseURL + "/api/expiration_dates/" + this.ticker)
      let app = this;
      const headers = this.authHeader

      fetch(fetchURL.toString(), { headers })
        .then(async response => {
          const data = await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }

          var sanitized = []
          for (let i = 0; i < data.length; i++) {
            var isoDateStr = new Date(data[i]).toISOString();
            var tIndex = isoDateStr.indexOf("T")
            if (tIndex == -1) {
              continue
            }

            sanitized.push(isoDateStr.substring(0, tIndex))
          }

          sanitized.sort(function(a, b) {
            return new Date(a) - new Date(b)
          })

          for (let i = 0; i < sanitized.length; i++) {
            let daysToExpiration = Math.ceil((new Date(sanitized[i]) - Date.now()) / (1000 * 3600 * 24))
            sanitized[i] = sanitized[i] + " (" + daysToExpiration + " DTE)"
          }

          app.expirationDays = sanitized
          return
        })
        .catch(error => {
          console.error("Error fetching options chain data for ticker ", this.ticker, ": ", error);
        });
    },
    onExpirationDateChanged() {
      if (this.ticker == null) {
        return
      }

      let fetchURL = new URL(this.baseURL + "/api/options/" + this.ticker)
      let params = fetchURL.searchParams

      if (this.expiration != null && this.expiration != "") {
        let spaceIndex = this.expiration.indexOf(" ")
        params.set("exp", this.expiration.substring(0, spaceIndex))
      }

      let app = this;
      const headers = this.authHeader

      fetch(fetchURL.toString(), { headers })
        .then(async response => {
          const data = await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }

          app.populateDataTable(data)
          return
        })
        .catch(error => {
          console.error("Error fetching options chain data for ticker ", this.ticker, ": ", error);
        });
    },
    sendIronCondorRequest () {
      if (this.ticker == null) {
        return
      }

      let ironCondorURL = new URL(this.baseURL + "/api/iron_condor/" + this.ticker)
      let params = ironCondorURL.searchParams

      if (this.expiration != null && this.expiration != "") {
        let spaceIndex = this.expiration.indexOf(" ")
        params.set("exp", this.expiration.substring(0, spaceIndex))
      }

      if (this.minNetDelta != null && this.minNetDelta != "") {
        params.set("min_net_delta", this.minNetDelta)
      }

      if (this.maxNetDelta != null && this.maxNetDelta != "") {
        params.set("max_net_delta", this.maxNetDelta)
      }

      let app = this;
      const headers = this.authHeader
      fetch(ironCondorURL.toString(), { headers })
        .then(async response => {
          const data = await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }

          if (data.job_id != null) {
            let jobID = data.job_id
            let statusURL = this.baseURL + "/api/iron_condor/status/" + jobID

            var pollInterval = setInterval(function() {
              fetch(statusURL, { headers })
                .then(async response => {
                  const data = await response.json();

                  app.requestStatus = data

                  if (data.Error !== null) {
                    clearInterval(pollInterval)
                    return
                  }

                  if (data.Result === null) {
                    return
                  }

                  clearInterval(pollInterval)

                  app.populateResultTable(data.Result)
                  return
                })
                .catch(error => {
                  clearInterval(pollInterval)
                  console.error("Error fetching status for job ", jobID, ": ", error);
                });
            }, 1000)

            return
          }

          // If the response isn't an individual job_id, then it is an array of job status.
          if (data.length == 0) {
            console.log("no results")
            return
          }

          if (data.length > 1) {
            data.sort(function(a, b) {
              if (a.Result == null) {return 1}
              if (b.Result == null) {return -1}
              if (a.Result.expiration_days < 1) {return 1}
              if (b.Result.expiration_days < 1) {return -1}
              return a.Result.expiration_days - b.Result.expiration_days
            })
          }

          app.populateResultTable(data[0].Result);
        })
        .catch(error => {
          console.error("Error fetching stats for ticker ", this.ticker, ": ", error);
        });
    },
    populateDataTable(result) {
      this.optionsData = [];
      result.strike_prices.forEach(element => {
        this.optionsData.push([
          element.call ? element.call.ask.toFixed(2) : "-",
          element.call ? element.call.bid.toFixed(2) : "-",
          element.call ? element.call.delta.toFixed(3) : "-",
          element.call ? element.call.theta.toFixed(3) : "-",
          element.strike.toString(),
          element.put ? element.put.theta.toFixed(3) : "-",
          element.put ? element.put.delta.toFixed(3) : "-",
          element.put ? element.put.bid.toFixed(2) : "-",
          element.put ? element.put.ask.toFixed(2) : "-",
        ])
      });

      this.$refs.optionDataTable.update(this.optionsData);
    },
    populateResultTable(result) {
      this.kellyCombinations = result.combinations

      let kellyRows = new Array(result.combinations.length);
      for (let i = result.combinations.length-1; i > -1; i--) {
        let element = result.combinations[i];
        kellyRows[i] = [
          element.stats.KellyCriterion.toFixed(3),
          element.strike_values[0],
          element.strike_values[1],
          element.strike_values[2],
          element.strike_values[3],
          element.stats.NetDelta.toFixed(3),
        ]
      }

      let app = this;
      this.$refs.comboListTable.update(kellyRows, function(index, combo) {
        app.selectedCombo = combo
        app.$refs.kellyStatsBox.value = JSON.stringify(app.kellyCombinations[index].stats, null, 2)
        app.$refs.optionDataTable.setSelected(combo)
      })
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.container {
  display: flex
}

.item {
  flex-basis: 100px;
  height: 200px;
  margin: 4px;
}
</style>
